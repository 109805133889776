jQuery(document).ready(function(e) {
// get current url
    var location = window.location.href;

// remove active class from all
    $(".nav-item a").removeClass('active');

// add active class to div that matches active url
    $(".nav-item a[href='"+location+"']").addClass('active');
});
$( document ).ready( function () {
    $(function() {
        var checkbox = $("#delivery");
        var hidden = $("#delivery-area");
        hidden.hide();
        checkbox.change(function() {
            if (checkbox.is(':checked')) {

                hidden.show();
            } else {
                hidden.hide();
            }
        });
    });

});
$( document ).ready( function () {
    $(function() {
        var checkbox = $("#collection");
        var hidden = $("#collection-area");
        hidden.hide();
        checkbox.change(function() {
            if (checkbox.is(':checked')) {

                hidden.show();
            } else {
                hidden.hide();
            }
        });
    });

});
$( document ).ready( function () {
    $(function() {

        // Get the form fields and hidden div
        var checkbox = $("#same_location");
        var hidden = $("#cond_return_location");


        hidden.hide();

        // Setup an event listener for when the state of the
        // checkbox changes.
        checkbox.change(function() {
            // Check to see if the checkbox is checked.
            // If it is, show the fields and populate the input.
            // If not, hide the fields.
            if (checkbox.is(':checked')) {
                // Show the hidden fields.
                hidden.show();
            } else {
                // Make sure that the hidden fields are indeed
                // hidden.
                hidden.hide();

                // You may also want to clear the value of the
                // hidden fields here. Just in case somebody
                // shows the fields, enters data to them and then
                // unticks the checkbox.
                //
                // This would do the job:
                //
                // $("#hidden_field").val("");
            }
        });
    });

});
$(document).ready(function() {

    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.move-top').fadeIn();
        } else {
            $('.move-top').fadeOut();
        }
    });

    $('.move-top').click(function() {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

});
// Home Page

$.extend(true, $.fn.datetimepicker.defaults, {
    icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'fas fa-arrow-alt-circle-up',
        down: 'fas fa-arrow-alt-circle-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'far fa-times-circle'
    }
});
$(function () {
    $('#start_date').datetimepicker({
        format: 'YYYY-MM-DD',
        minDate: moment(moment()).add(1, 'days')
    });
    $('#end_date').datetimepicker({
        useCurrent: false,
        format: 'YYYY-MM-DD'
    });
    $("#start_date").on("dp.change", function (e) {
        $('#end_date').data("DateTimePicker").minDate(e.date);
    });
    $("#end_date").on("dp.change", function (e) {
        $('#start_date').data("DateTimePicker").maxDate(e.date);
    });
});
$(function () {
    $('#start_time').datetimepicker({
        format: 'HH:mm',
        stepping: 15
    });
    $('#end_time').datetimepicker({
        useCurrent: false,
        format: 'HH:mm',
        stepping: 15
    });
});

// End Home Page
$(document).ready(function () {
   $('#one-next').click(function (e) {
       e.preventDefault();
       $('#step-one').addClass('tab-inactive');
       $('#step-two').addClass('tab-active');

   });
    $('#two-prev').click(function (e) {
        e.preventDefault();
        $('#step-one').removeClass('tab-inactive');
        $('#step-two').removeClass('tab-active').addClass('tab-inactive');
        $('#feedback-block').addClass('gone');
    });
});

$(function(){
    $('#pickup_location select').change(function(){ // when one changes
        $('#return_location select').val( $(this).val() ) // they all change
    })
})

$(document).ready(function () {
    $( "#rental-form" ).validate( {
        rules: {
            start_date: "required",
            end_date: "required",
            start_time: "required",
            end_time: "required",
            vehicle_group: "required"
        },
        messages: {
            start_date: "Please enter a valid Starting Date YYYY-MM-DD",
            end_date: "Please enter a valid End Date YYYY-MM-DD",
            start_time: "What time will you collect your vehicle?",
            end_time: "What time will you return your vehicle?",
            vehicle_group: "Please select a vehicle group"
        },
        errorElement: "p",
        errorPlacement: function ( error, element ) {
            // Add the `invalid-feedback` class to the error element
            error.addClass( "invalid-feedback" );

            if ( element.prop( "type" ) === "checkbox" ) {
                error.insertAfter( element.next( "label" ) );
            } else {
                error.insertAfter( element );
            }
        },
        highlight: function ( element, errorClass, validClass ) {
            $( element ).addClass( "is-invalid" ).removeClass( "is-valid" );
        },
        unhighlight: function (element, errorClass, validClass) {
            $( element ).addClass( "is-valid" ).removeClass( "is-invalid" );
        }
    });

});


$(document).ready(function () {
    $('#rental-form').submit(function (e) {
        e.preventDefault();
        $.ajax({
            type: "POST",
            url: document.location.origin + "/php/booking-reply-type.php",
            data: $('#rental-form').serialize(),
            success: function (data) {
                $('.tab').addClass('tab-inactive').removeClass('tab-active');
                $('#end-form').removeClass('tab-inactive');
                var messageAlert = 'alert-' + data.type;
                var messageText = data.message;
                var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';
                if (messageAlert && messageText) {
                    $('#end-form').find('.messages').html(alertBox);
                }
                $('#rental-form label').addClass('gone');
                $('#rental-form input').prop('hidden', true);
                $('#rental-form')[0].reset();
            }
        })
    })
});

